<template>
    <v-row>
        <v-col cols="12" class="mt-2">


            <validation-observer ref="observer"
                                 v-slot="{ invalid }"
                                 class="mt-8"
            >

                <form class="form"
                      style="width: 100%"
                      action=""
                      @submit.prevent="updateUserInfo"
                >

                    <div>
                        <label class="user_info_label">
                            이름
                        </label>

                        <validation-provider v-slot="{ errors }"
                                             name="유저 이름"
                                             rules="required"
                        >
                            <v-text-field v-model="user_info.user_name"
                                          outlined
                                          :error-messages="errors"
                                          required
                                          dense
                                          v-on:keyup.enter="updateUserInfo"
                            ></v-text-field>
                        </validation-provider>
                    </div>

                    <div class="mt-2">
                        <label class="user_info_label">
                            Email
                        </label>

                        <v-text-field v-model="user_info.user_email"
                                      outlined
                                      dense
                                      disabled
                        ></v-text-field>
                    </div>

                    <div class="mt-2">
                        <label class="user_info_label">
                            연락처
                        </label>

                        <validation-provider v-slot="{ errors }"
                                             name="연락처"
                                             rules="required"
                        >
                            <v-text-field v-model="user_info.user_phone"
                                          outlined
                                          :error-messages="errors"
                                          required
                                          dense
                                          type="number"
                                          placeholder="- 빼고 입력"
                                          v-on:keyup.enter="updateUserInfo"
                            ></v-text-field>
                        </validation-provider>
                        <div class="d-flex justify-end">
                            <v-btn rounded
                                   depressed
                                   :dark="user_info.authentication_yn==false? true : false"
                                   :color="user_info.authentication_yn==false? '#5EB4F9' : ''"
                                   :disabled="user_info.authentication_yn==false ? false:true"
                                   @click="user_info.authentication_yn = !user_info.authentication_yn"
                            >
                                {{ user_info.authentication_yn==false ? '인증확인' : '인증완료'}}
                            </v-btn>
                        </div>
                    </div>

                    <v-btn color="#primary"
                           class="mt-6"
                           depressed
                           rounded
                           large
                           block
                           :disabled="invalid"
                           style="color:#ffffff"
                           @click="updateUserInfo"
                    >
                        수정
                    </v-btn>
                </form>
            </validation-observer>
        </v-col>

        <v-snackbar v-model="snackbar">
            {{ snackbar_msg }}

            <template v-slot:action="{ attrs }">
                <v-btn
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="closePage"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-overlay :value="loading_bar">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>

    </v-row>
</template>

<script>
    import { required } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
    import axios from '../../../service/axios'

    setInteractionMode('eager');

    extend('required', {
        ...required,
        message: '{_field_} 입력해주세요',
    });

    export default {
        name: 'UserInfo',
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        data(){
            return {
                user_info:{},
                snackbar: false,
                snackbar_msg: null,
                loading_bar: false,
            }
        },
        created () {
            this.selectUserData();
        },
        methods: {
            selectUserData(){
                this.loading_bar = true;
                const params = {
                    accessKey: this.$cookies.get('token'),
                }
                axios.post('/users/info', params).then(res =>{
                    this.user_info = res.data.data.item
                    console.log('User Info : ', this.user_info )
                    this.loading_bar = false;
                }).catch(error=>{
                    console.log('페스트 오류', error)
                    this.loading_bar = false;
                });
            },
            updateUserInfo(){
                if(this.user_info.authentication_yn == true){
                    this.loading_bar = true;
                    const params = {
                        user_info: this.user_info,
                        accessKey: this.$cookies.get('token'),
                    }
                    axios.post('/api/user/update', params).then(res =>{
                        this.user_info = res.data.data;
                        this.snackbar_msg = '정보가 수정되었습니다.';
                        this.snackbar = true;
                        this.selectUserData();
                    }).catch(error=>{
                        this.loading_bar = false;
                        console.log('페스트 오류', error)
                    });
                }
            },
            closePage(){
                this.snackbar_msg = null;
                this.snackbar = false;
            }
        },
    }

</script>

<style lang="scss">
    .user_info_label{
        font-size: 0.8rem !important;
        color: #969696 !important;
        letter-spacing: -0.07em;
    }
    input[type="text"] {
        border-radius: 5px;
        font-size: 16px;
        line-height: 20px;
        padding: 5px;
        width: 100%;
    }
</style>
